import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/packages/recipes/src/layouts/recipe.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h5>{`Prep time: 30 min. `}<meta itemProp="prepTime" content="PT30M" /></h5>
    <h5>{`Cook time: 90 min. `}<meta itemProp="cookTime" content="PT90M" /></h5>
    <h5>{`Servings: `}<span itemProp="recipeYield">{`4-6`}</span></h5>
    <hr></hr>
    <h2>{`Ingredients`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Chicken thighs, 2-3 chopped</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Jasmine rice, 2 cups</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Chicken broth, 32 oz.</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Frozen corn, 1 cup</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Sesame oil, 1 tbs. and for garnish</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Eggs, 4</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Sambal, for garnish</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Fried shallots, for garnish</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Fish sauce, for garnish</span>
      </li>
      <li parentName="ul">
        <span itemProp="recipeIngredient">Green onions, chopped for garnish</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Equipment`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="tool">Big pot</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Prep`}</h2>
    <ul>
      <li parentName="ul">
        <span itemProp="step">Wash rice</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Chop chicken into small, bite-sized pieces</span>
      </li>
      <li parentName="ul">
        <span itemProp="step">Chop green onions</span>
      </li>
    </ul>
    <hr></hr>
    <h2>{`Instructions`}</h2>
    <span itemProp="recipeInstructions">
      <ol>
        <li parentName="ol">
          <p parentName="li">{`Bring 1 inch of chicken broth to a boil.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Add sesame oil and chicken, cook until chicken is no longer translucent.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Add remaining chicken broth, rice, and 4 cups water.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Cook for at least 45 minutes, adding water to desired consistency.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`With about 15 minutes to go, scramble 2 eggs at a time and mix into the porridge.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Add corn.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Garnish with any of: sambal, fried shallots, fish sauce, sesame oil, and green onions.`}</p>
        </li>
      </ol>
    </span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      